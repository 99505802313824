<template>
    <div>
        <div class="row" v-if="btnIndex == 1">
            <div class="col-12 col-lg-7">
                <b-card :header="$t('session_information')" class="soft">
                    <div class="row">
                        <div class="col-12">
                            <b-form-group :label="$t('classroom')">
                                {{ selectedData?.classroom_name }}
                            </b-form-group>
                        </div>

                        <div class="col-12">
                            <b-form-group :label="$t('course_name')">
                                {{ selectedData?.course_name }}
                            </b-form-group>
                        </div>
                        <div class="col-12">
                            <b-form-group :label="$t('start_date')">
                                {{ selectedData?.start_date }}
                            </b-form-group>
                        </div>
                        <div class="col-12">
                            <b-form-group :label="$t('end_date')">
                                {{ selectedData?.end_date }}
                            </b-form-group>
                        </div>

                    </div>
                </b-card>
            </div>
            <div class="col-12 col-lg-5">
                <b-card :header="$t('processes')" class="soft">
                    <b-card-body class="d-flex flex-column flex-sm-row flex-wrap" style="gap:8px">
                        <b-button variant="outline-primary" style="line-height:1" @click="attendanceEntry">{{
                                $t('prep_attendance').toUpper()
                            }}
                        </b-button>
                        <b-button variant="outline-primary" style="line-height:1" @click="studentListWithPhoto">
                            {{ $t('student_list_with_photo').toUpper() }}
                        </b-button>
                        <b-button variant="outline-primary" @click="studentList">{{
                                $t('student_list').toUpper()
                            }}
                        </b-button>
                        <b-button variant="outline-primary" @click="goToAssessmentNoteEntry">{{
                                $t('assessment_note_entry').toUpper()
                            }}
                        </b-button>
                    </b-card-body>
                </b-card>
            </div>
        </div>
        <students @back="courseDetails" v-if="btnIndex === 2" :sessionId="selectedData.id"/>

        <students-with-photo @back="courseDetails" v-if="btnIndex ===3" :sessionId="selectedData.id"/>

    </div>
</template>
<script>
import StudentsWithPhoto from "@/modules/eslInstructorSessions/pages/StudentsWithPhoto.vue";
import Students from "@/modules/eslInstructorSessions/pages/Students.vue"

export default {
    components: {
        StudentsWithPhoto,
        Students
    },
    props: {
        selectedData:{
            default: {},
            type:Object,
        }
    },
    data() {
        return {
            viewData: [],
            section: null,
            sectionOptions: [],
            btnIndex: 1,
            semester: null,
            sectionNo: null,
            process: null,
        }
    },
    metaInfo() {
        return {
            title: this.$t("instructor_courses_title")
        }
    },
    methods: {
        attendanceEntry() {
            if (this.selectedData?.id!= null) {
                this.$router.push('/esl/attendance/' + this.selectedData?.id)
            } else {
                this.$toast.error('error')
            }
        },
        goToAssessmentNoteEntry(){
            this.$router.push('/assessment/bulk/entry/' + this.selectedData?.id)

        },
        studentListWithPhoto() {
                this.btnIndex = 3
        },
        courseDetails() {
            this.btnIndex = 1
        },

        studentList() {
                this.btnIndex++
        },
    },
};
</script>

