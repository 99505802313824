<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('esl_instructor_sessions')" :isFilter="false" />
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('esl_instructor_sessions')" :isFilter="false" />
            </template>
            <div class="mb-5 align-items-center">
                <b-card-group deck>
                    <div v-for="(item, key) in sessions" class="col-12 col-sm-6 col-md-6 col-lg-3 mb-4" :key="key">
                        <b-card bg-variant="light" :header="item.classroom_name" class="text-center cursor"
                                @click="showDetail(item.id)">
                            <b-card-text>{{ item.course_name }}</b-card-text>
                        </b-card>
                    </div>
                </b-card-group>
                <template v-if="sessions.length < 1"><div class="text-center">
                    <p class="text-black-90">{{ $t('no_course') }}</p>
                </div> </template>
            </div>
            <CommonModal size="xl" ref="detailModal" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('course_detail_title').toUpper() }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <SessionDetails v-if="process == 'course_detail'" :selectedData="selectedData"  ></SessionDetails>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import qs from 'qs'
// Template
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

// Page
import CourseDetail from "@/modules/sections/pages/instructor/CourseDetail";

// Component
import CommonModal from "@/components/elements/CommonModal";

import EslInstructorService from "@/services/EslInstructorService";
import SessionDetails from "@/modules/eslInstructorSessions/pages/SessionDetails.vue";

export default {
    components: {
        SessionDetails,
        AppLayout,
        Header,
        HeaderMobile,
        CommonModal,
        CourseDetail,
    },
    data() {
        return {
            selectedData: null,
            sessions: [],
            selectedId: null,
            process: null,
            filter: {}
        }
    },
    metaInfo() {
        return {
            title: this.$t("esl_instructor_sessions")
        }
    },
    methods: {
        getData() {
            const {id} =localStorage.getItem("user")?JSON.parse(localStorage.getItem("user")):null;

            EslInstructorService.getInstructorSession(id)
                .then(response => {
                    this.sessions = response.data.data
                })
        },
        showDetail(sessionId) {
            if (this.checkPermission('section_instructor')) {
                this.process = 'course_detail'
                 this.selectedData = this.sessions.find(session => session.id === sessionId)

                this.$refs.detailModal.$refs.commonModal.show()
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }

        },
        formClear() {
            this.process = null;
            this.selectedData=null
            this.$refs.detailModal.$refs.commonModal.hide()
        },
        filterClear() {
            this.filter = {}
            this.getData()
        },
    },
    created() {
        this.getData()
    }
}
</script>
<style scoped>
.cursor {
    cursor: pointer;
}
</style>
