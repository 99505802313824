<template>
    <div>
        <b-row >
            <b-col md="6">
                <h6 class="mb-2">{{ $t('student_list_with_photo') }}</h6>
            </b-col>
            <b-col md="6" class="d-flex justify-content-end">
                <b-button variant="primary" class="mb-2" @click="print">{{ $t('print') }}</b-button>
            </b-col>
        </b-row>
        <div id="printMe">
            <b-row>
                <b-col
                    v-for="(item, key) in students"
                    cols="12" sm="6" md="4" lg="4"
                    :key="key"
                >
                    <b-card
                        :img-src="item.photo ? item.photo : require('@/assets/img/no-photo.jpg')"
                        :img-alt="item.name + ' ' + item.surname"
                        img-top tag="article"
                        style="max-width: 20rem; height: 100%;"
                        class="mb-4 card-fixed"
                    >
                        <b-card-title class="mb-1">
                            {{ item.student_number }}
                        </b-card-title>
                        <b-card-text>
                            {{ item.name + ' ' + item.surname }} <br />
                        </b-card-text>
                    </b-card>
                </b-col>
            </b-row>
        </div>
    </div>
</template>
<script>

import EslAttendanceService from "@/services/EslAttendanceService";

export default {
    name: "StudentsWithPhoto",
    props: {
        sessionId: {
            default: null,
            type: Number
        },

    },
    data() {
        return {
            students: [],
        }
    },
    metaInfo() {
        return {
            title: this.$t("instructor_courses_title")
        }
    },
    methods: {
        getData() {
            EslAttendanceService.getStudents(this.sessionId).then(response => {
                response.data.data.forEach(item => {
                    this.students.push({
                        student_number: item.student_number,
                        name: item.name,
                        surname: item.surname,
                        photo: item.photo,
                    })
                })

            })
        },

        goBack() {
            this.$emit('back')
        },

        print() {
            const options = {
                autoClose:false
            }
            this.$htmlToPaper('printMe',options)
        }
    },

    created() {
        this.getData()
    },

};
</script>
<style scoped>
.card-fixed {
    display: flex;
    flex-direction: column;
    height: 100%;
}


.card-fixed .card-body {
    flex-grow: 1;
}
</style>
