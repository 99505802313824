<template>
    <div>
        <h6 class="mb-2">{{ $t('student_list') }}</h6>
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                <b-table striped hover responsive :items="students" :fields="fields">

                </b-table>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex">
                <b-button variant="primary" class="mr-1" @click="goBack">{{ $t('back').toUpper() }}</b-button>
                <json-excel type="xls"
                            :data="studentExcelData"
                            :fields="studentExcelField">
                    <b-button variant="success">
                        <i class="ri-file-excel-2-line mr-2"></i> {{ $t('excel') }}
                    </b-button>
                </json-excel>
            </div>

        </div>
    </div>
</template>
<script>

import JsonExcel from "vue-json-excel";
import EslAttendanceService from "@/services/EslAttendanceService";


export default {
    components: {
        JsonExcel
    },
    props: {
        sessionId:{
            type: Number,
        }
    },
    data() {
        return {
            students: [],
            fields: [
                {
                    key: 'student_number',
                    label: this.$t('student_number')
                },

                {
                    key: 'name',
                    label: this.$t('name')
                },
                {
                    key: 'surname',
                    label: this.$t('surname')
                },
                {
                    key: 'student_program_id',
                    label: this.$t('student_program_id')
                },
                {
                    key: 'session_id',
                    label: this.$t('session')
                },

            ],
            studentExcelField: {
                [this.$t('student_number')]: 'student_number',
                [this.$t('name')]: 'name',
                [this.$t('surname')]: 'surname',
                [this.$t('student_program_id')]: 'student_program_id',
                [this.$t('session')]: 'session_id',
            },
            studentExcelData: [],
        }
    },
    metaInfo() {
        return {
            title: this.$t("instructor_courses_title")
        }
    },
    methods: {
       async getData() {
           EslAttendanceService.getStudents(this.sessionId).then(response =>{
                    response.data.data.forEach(item => {
                        this.students.push({
                            student_number: item.student_number,
                            name: item.name,
                            surname: item.surname,
                            photo: item.photo,
                            session_id: item.session_id,
                            student_program_id: item.student_program_id,
                        })
                    })
                }).finally(this.studentExcelData=this.students)
        },
        goBack() {
            this.$emit('back')
        }
    },
    created() {
        this.getData()
    },

};
</script>
